import { Input } from "@shared/ui/Inputs";
import { CaretLeft, MagnifyingGlass } from "phosphor-react";
import React from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { atom, useRecoilState, useRecoilValue } from "recoil";
import { ClosedTicketsAtom } from "~/atoms/_closedTicket";
import { MachinesAtom } from "~/atoms/_machine";
import { OpenTicketsAtom } from "~/atoms/_openTicket";
import ResponsiveHeader from "~/components/header/_innerResp";

export const headerAtom = atom({
  key: "headerOptions",
  default: {
    title: "",
    lead: "",
    url: "",
  },
});

export default ({ header, ...props }) => {
  const history = useHistory();
  const { messages } = useIntl();

  const [machineCoil, setMachineCoil] = useRecoilState(MachinesAtom);

  var handleBack = () => {
    if (typeof header.backUrl === "undefined") {
      history.goBack();
    } else {
      history.replace(header.backUrl);
    }
  };

  const handleMachineSearch = (e) => {
    const { value } = e.target;

    setMachineCoil({
      ...machineCoil,
      search: {
        ...machineCoil?.search,
        value,
      },
    });
  };

  return (
    <>
      <ResponsiveHeader header={header} {...props} />
      <div className={`o-page-innerHeader ${header.headerClass || ""}`}>
        {header?.backIcon && (
          <span onClick={handleBack} className="o-page-innerHeaderBack">
            <button className="o-page-innerHeaderBackIcon btn-v2 ghost-btn-v2 only-icon-btn-v2">
              <CaretLeft size={16} />
            </button>
            <span className="o-page-innerHeaderBackLabel">
              {props?.intl?.messages?.common?.back}
            </span>
          </span>
        )}
        {header?.counter && renderCounter(header?.page)}

        {header?.action && renderAddButton(header?.page, header?.action?.label)}

        {header?.page === "machines" && header?.action && (
          <div className="header-search-container">
            <Input
              icon={<MagnifyingGlass size={16} />}
              className="p-9"
              value={machineCoil?.search?.value}
              placeholder={messages?.machines?.searchPlaceholder}
              onChange={handleMachineSearch}
            />
          </div>
        )}
      </div>
    </>
  );
};

const renderCounter = (page, ...props) => {
  const { messages } = useIntl();

  if (page === "open-tickets") {
    const { totalTickets, totalUnreadTickets } =
      useRecoilValue(OpenTicketsAtom);

    return (
      <span className="page-header-counter">
        {totalTickets} {messages?.tickets?.listing.title} &nbsp; &#8226; &nbsp;{" "}
        {totalUnreadTickets} {messages?.tickets?.unread}
      </span>
    );
  } else if (page === "closed-tickets") {
    const { totalTickets } = useRecoilValue(ClosedTicketsAtom);

    return <span className="page-header-counter">{totalTickets} Tickets</span>;
  } else if (page === "machines") {
    const { totalMachines } = useRecoilValue(MachinesAtom);

    return (
      <span className="page-header-counter">
        {totalMachines} {messages?.machines?.totalMachineNumber}
      </span>
    );
  }
};

export const renderAddButton = (page, label, ...props) => {
  if (page === "open-tickets") {
    const [ticketOpenCoil, setTicketOpenCoil] = useRecoilState(OpenTicketsAtom);

    return (
      <button
        className="btn-v2 primary-btn-v2 u-padding-x-3"
        onClick={() =>
          setTicketOpenCoil({ ...ticketOpenCoil, isDrawerOpen: true })
        }
      >
        <span>{label}</span>
      </button>
    );
  } else if (page === "closed-tickets") {
    const [ticketClosedCoil, setTicketClosedCoil] =
      useRecoilState(ClosedTicketsAtom);

    return (
      <button
        className="btn-v2 secondary-btn-v2 add-facility-btn"
        onClick={() =>
          setTicketClosedCoil({ ...ticketClosedCoil, isDrawerOpen: true })
        }
      >
        <span>{label}</span>
      </button>
    );
  }
};
