import AppHeader from "@shared/ui/AppHeader";
import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { useRecoilState, useRecoilValue } from "recoil";
import { unreadChannelMessagesAtom } from "~/atoms/_chat.jsx";
import { OpenTicketsAtom } from "~/atoms/_openTicket";
import { TicketItemClosed } from "~/components/_ticketCard";
import Headline, { HEADLINE_SIZES } from "@shared/ui/Headline";
import { NoTicketsLarge } from "~/components/general/_no-tickets";
import Actions from "~/components/header/Actions";
import Hamburger from "~/components/header/Hamburger";
import { useListOwnCustomerTickets } from "~/services";
import CreateWorkOrder from "./Create";
import mixpanel from "mixpanel-browser";
import { isMixpanelEnabled } from "~/utils/_mixpanel";
import { ITEMS_PER_PAGE, VIEW_CLOSED_WORK_ORDERS } from "~/constants/global";
import { TicketLoaderComponent } from "#/src/components/skeletonLoaders/_ticketLoaders";

export default ({ intl }) => {
  const { messages } = useIntl();
  const unreadChannelMessages = useRecoilValue(unreadChannelMessagesAtom);
  const [openTicketCoil, setOpenTicketCoil] = useRecoilState(OpenTicketsAtom);
  const notificationChannels = unreadChannelMessages?.channels;
  const notificationKeys = Object.keys(notificationChannels);

  const {
    tickets: data,
    loading,
    totalCount,
    handleFetchMore,
  } = useListOwnCustomerTickets({
    where: {
      myClosedWorkOrders: true,
    },
  });

  const handleScrollToBottom = (event) => {
    if (
      data.length >= ITEMS_PER_PAGE &&
      !loading &&
      event?.previousPosition !== "above" &&
      data.length < totalCount
    ) {
      handleFetchMore({
        limit: ITEMS_PER_PAGE,
        skip: data?.length,
      });
    }
  };

  const renderTickets = () => {
    return (
      <>
        {!data.length && loading && <TicketLoaderComponent total={1} />}
        {!data.length && !loading && (
          <NoTicketsLarge
            intl={intl}
            title={messages?.tickets?.noClosedWorkOrders}
          />
        )}

        {data?.length > 0 && (
          <div className="page-list-item-wrapper has-bottom-actions">
            <TicketItemClosed
              intl={intl}
              data={data}
              onScrollBottom={handleScrollToBottom}
              loading={loading}
            />
          </div>
        )}
      </>
    );
  };

  useEffect(() => {
    if (!isMixpanelEnabled()) return;
    mixpanel.track(VIEW_CLOSED_WORK_ORDERS);
  }, []);

  return (
    <>
      <AppHeader padding="py-xl lg:py-lg" className="mx-xl md:mx-2xl mb-md">
        <Headline size={HEADLINE_SIZES.SMALL}>
          {messages?.header.closedTicketListing.title} ({totalCount ?? 0})
        </Headline>
        <div className="flex items-center desktop-element">
          <CreateWorkOrder />
        </div>
        {/* Hamburger component is only for mobile view */}
        <Hamburger />
      </AppHeader>

      {renderTickets()}
      {/* Actions component is only for mobile view */}
      <Actions
        onClick={() =>
          setOpenTicketCoil({ ...openTicketCoil, isDrawerOpen: true })
        }
      />
    </>
  );
};
