import { useQuery } from "@apollo/client";
import Headline, { HEADLINE_SIZES } from "@shared/ui/Headline";
import Toast, { TOAST_TYPES } from "@shared/ui/Toast";
import React from "react";
import { injectIntl } from "react-intl";
import { Redirect, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { GET_OEM_BY_QUERY } from "../api";

import { ForgotPasswordSuccessForm as Form } from "~/components/form";
import { useBrandingProperties } from "~/hooks";
import { forgotPassword } from "~/services";

const ForgotPasswordSuccess = ({ intl }) => {
  const { modifier } = useParams();

  const { mutation } = forgotPassword();

  const { data: { getOemBySlug: oem } = {}, error } = useQuery(
    GET_OEM_BY_QUERY,
    {
      variables: {
        slug: modifier,
      },
    },
  );

  const { branding, isLighterBackground, buttonStyle } = useBrandingProperties(
    oem,
    intl,
  );

  if (error) {
    if (typeof modifier !== "undefined") {
      return (
        <Redirect
          to={{
            pathname: "/error-page/404",
          }}
        />
      );
    }
  }

  var renderPage = () => {
    return (
      <div className="auth">
        <div className="o-container">
          <div className="auth-main auth-forgot-password-success-main">
            <div className="auth-box auth-forgot-password-success-box">
              <Headline
                size={HEADLINE_SIZES.SMALL}
                className="text-center mb-sm"
              >
                {intl.messages?.forgotPasswordSuccess?.welcome}
              </Headline>

              <p className="subtitle">
                {intl.messages?.forgotPasswordSuccess?.title}
              </p>

              <Form
                color={branding?.backgroundColor}
                isLighterBackground={isLighterBackground}
                buttonStyle={buttonStyle}
                intl={intl}
                callback={(values) => {
                  mutation(values)
                    .then(() => {
                      toast(
                        <Toast
                          message={
                            intl.messages?.forgotPasswordSuccess?.form
                              ?.resendEmailSuccess
                          }
                        />,
                        {
                          closeButton: false,
                        },
                      );
                    })
                    .catch((e) => {
                      toast(
                        <Toast
                          message={
                            e?.message || intl.messages?.login?.wrongCreds
                          }
                          type={TOAST_TYPES.ERROR}
                        />,
                        {
                          closeButton: false,
                        },
                      );
                    });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return renderPage();
};

export default injectIntl(ForgotPasswordSuccess);
