import React from "react";
import { MagnifyingGlass } from "phosphor-react";
import { useIntl } from "react-intl";
import { SMALL_DEVICE_WIDTH } from "#/src/constants/global";
import useWindowDimensions from "#/src/hooks/_useWindowDimensions";

const MobileSearch = ({
  value,
  placeholder,
  onChange,
  className,
  isMobileElement = true,
}) => {
  const { width: deviceWidth } = useWindowDimensions();
  const isNotDesktop = deviceWidth < SMALL_DEVICE_WIDTH;
  const { messages } = useIntl();

  const handleChange = (e) => {
    onChange(e?.target?.value);
  };

  const handleBlur = (e) => {
    if (isNotDesktop) onChange("");
  };

  return (
    <div
      className={`${
        isMobileElement ? "mobile-element " : ""
      } u-flex u-width-100 mobile-view-search ${className || ""}`}
    >
      <MagnifyingGlass size={16} />
      <input
        className="search-input"
        placeholder={placeholder || messages?.common?.search}
        onChange={handleChange}
        onBlur={handleBlur}
        value={value}
      />
    </div>
  );
};

export default MobileSearch;
