import AppHeader from "@shared/ui/AppHeader";
import Button, { BUTTON_VARIANTS } from "@shared/ui/Button";
import Headline, { HEADLINE_SIZES } from "@shared/ui/Headline";
import { Input } from "@shared/ui/Inputs";
import Toast, { TOAST_TYPES } from "@shared/ui/Toast";
import { Check, Checks, Globe, Key, WarningCircle, X } from "phosphor-react";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";

import EmptySpace from "../components/_emptySpace";
import IconInfo from "../components/_iconInfo";
import { DropdownMenuV2 } from "../components/form/_inputs-v2";
import { useAuth } from "../components/general";
import Hamburger from "../components/header/Hamburger";
import locales from "../config/locales";
import { SMALL_DEVICE_WIDTH } from "../constants/global";
import useWindowDimensions from "../hooks/_useWindowDimensions";
import { languageAtom } from "../LocaleHanlder";
import { useUpdatePersonalGeneralSettings } from "../services";
import { base64String, saveLocaleToStorage } from "../utils";

export default ({ intl }) => {
  const { common, settings } = intl?.messages;
  const { width: deviceWidth } = useWindowDimensions();
  const { logout } = useAuth(intl);
  const [languageOption, setLanguageOption] = useRecoilState(languageAtom);
  const initalLang = {
    ...languageOption?.locale,
    label: languageOption?.locale?.name,
  };
  const [lang, setLang] = useState([initalLang]);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newConfirmPassword, setNewConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showPasswordsMismatchError, setShowPasswordsMismatchError] =
    useState(false);
  const doPasswordsMatch =
    Boolean(newPassword) &&
    Boolean(newConfirmPassword) &&
    newPassword === newConfirmPassword;
  const [userData, setUserData] = React.useState({
    oldPassword: "",
    newPassword: "",
    newConfirmPassword: "",
  });

  const languageOptions = locales?.map((l) => {
    return {
      ...l,
      label: l.name,
      value: l.language,
    };
  });

  const { updatePersonalGeneralSettings } = useUpdatePersonalGeneralSettings();

  const resetFields = () => {
    setOldPassword("");
    setNewPassword("");
    setNewConfirmPassword("");
  };

  const clearErrors = () => {
    setPasswordError("");
    setShowPasswordsMismatchError(false);
  };

  const handlePasswordChange = async () => {
    if (newPassword !== newConfirmPassword) {
      setShowPasswordsMismatchError(true);
      return;
    }
    try {
      await updatePersonalGeneralSettings({
        ...(userData.oldPassword && { oldPassword: userData.oldPassword }),
        ...(userData.newPassword && { newPassword: userData.newPassword }),
        ...(userData.newConfirmPassword && {
          newConfirmPassword: userData.newConfirmPassword,
        }),
      });

      toast(<Toast message={settings?.password.passwordAlert} />, {
        closeButton: false,
      });

      if (userData?.newConfirmPassword) {
        logout();
      }
      setUserData({
        oldPassword: "",
        newPassword: "",
        newConfirmPassword: "",
      });
    } catch (error) {
      const message = error?.graphQLErrors?.[0]?.message;
      if (message) {
        setPasswordError(message);
      } else {
        toast(
          <Toast
            message={settings?.password?.somethingWrong}
            type={TOAST_TYPES.ERROR}
          />,
          {
            closeButton: false,
          },
        );
      }
    }
  };

  return (
    <>
      <AppHeader padding="py-xl" className="mx-xl md:mx-2xl mb-xl md:mb-2xl">
        <Headline size={HEADLINE_SIZES.SMALL}>
          {deviceWidth > SMALL_DEVICE_WIDTH
            ? settings?.title
            : settings?.responsiveTitle}
        </Headline>
        {/* Hamburger component is only for mobile view */}
        <Hamburger />
      </AppHeader>
      <div className="page-list-item-wrapper">
        <IconInfo icon={<Key size={16} />} info={settings?.password?.title} />
        <EmptySpace />
        <div className="language-setting-content">
          <div className="inner-left-content">
            <Input
              keyId="old-password"
              label={settings?.password?.old?.label}
              placeholder={settings?.password?.old?.placeholder}
              value={oldPassword}
              errorMessage={passwordError}
              type="password"
              onChange={(e) => {
                if (passwordError) setPasswordError("");
                setOldPassword(e.target.value.trim());
                setUserData({
                  ...userData,
                  oldPassword: base64String(e.target.value.trim()),
                });
              }}
            />
            <EmptySpace height="24px" />
            <Input
              keyId="new-password"
              label={settings?.password?.new.label}
              placeholder={settings?.password?.new.placeholder}
              value={newPassword}
              type="password"
              onChange={(e) => {
                if (showPasswordsMismatchError) {
                  setShowPasswordsMismatchError(false);
                }
                setNewPassword(e.target.value.trim());
                setUserData({
                  ...userData,
                  newPassword: base64String(e.target.value.trim()),
                });
              }}
            />
            <EmptySpace height="16px" />
            <Input
              keyId="confirm-new-password"
              label={settings?.password?.confirm?.label}
              placeholder={settings?.password?.confirm?.placeholder}
              value={newConfirmPassword}
              type="password"
              onChange={(e) => {
                if (showPasswordsMismatchError) {
                  setShowPasswordsMismatchError(false);
                }
                setNewConfirmPassword(e.target.value.trim());
                setUserData({
                  ...userData,
                  newConfirmPassword: base64String(e.target.value.trim()),
                });
              }}
            />
            <EmptySpace height="8px" />
            {doPasswordsMatch && (
              <div className="input-v2-success">
                <div className="checks-wrapper">
                  <Checks size={8} weight="bold" />
                </div>
                <p className="input-v2-success-label u-padding-l-0">
                  {settings?.password.passwordMatched}
                </p>
              </div>
            )}

            {showPasswordsMismatchError && (
              <p className="input-v2-password-error-label">
                <WarningCircle className="input-v2-error-icon" size={16} />
                {settings?.password.passwordNotMatched}
              </p>
            )}

            {userData.oldPassword && (
              <>
                <EmptySpace height="16px" />
                <div className="flex gap-md">
                  <Button
                    variant={BUTTON_VARIANTS.LINK}
                    text={settings?.password.save}
                    leadingIcon={<Check size={16} />}
                    disabled={
                      !userData.oldPassword ||
                      !userData.newPassword ||
                      !userData.newConfirmPassword
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      if (
                        !userData.oldPassword ||
                        !userData.newPassword ||
                        !userData.newConfirmPassword
                      ) {
                        return;
                      }
                      handlePasswordChange();
                    }}
                  />
                  <Button
                    variant={BUTTON_VARIANTS.DANGER_LINK}
                    text={settings?.password.discard}
                    leadingIcon={<X size={16} weight="fill" />}
                    onClick={(e) => {
                      e.preventDefault();
                      setUserData({
                        oldPassword: "",
                        newPassword: "",
                        newConfirmPassword: "",
                      });
                      resetFields();
                      clearErrors();
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <EmptySpace height="12px" />
        <section className="settings-section u-padding-t-4">
          <IconInfo icon={<Globe size={16} />} info={settings?.language} />
          <div className="settings-section-content language-setting-content mt-lg">
            <DropdownMenuV2
              uppercaseLabel={false}
              label={settings?.appLanguage}
              placeholder={common.changeLanguage}
              options={languageOptions}
              onChange={(selected) => {
                const lang = selected;
                setLang(lang);
                let langValue = lang?.[0];
                saveLocaleToStorage(langValue);
                setLanguageOption({
                  ...languageOption,
                  locale: {
                    ...languageOption.locale,
                    ...langValue,
                  },
                });
              }}
              values={lang}
            />
          </div>
        </section>
      </div>
    </>
  );
};
