import { MagnifyingGlass } from "phosphor-react";
import React, { useEffect, useRef, useState } from "react";

export const INPUT_SIZES = {
  NORMAL: "normal",
  SMALL: "small",
} as const;

type InputSizeType = (typeof INPUT_SIZES)[keyof typeof INPUT_SIZES];

const SIZE_HEIGHT_STYLES: Record<InputSizeType, string> = {
  [INPUT_SIZES.NORMAL]: "h-4xl",
  [INPUT_SIZES.SMALL]: "h-3xl",
};

const SIZE_WIDTH_STYLES: Record<InputSizeType, string> = {
  [INPUT_SIZES.NORMAL]: "w-4xl",
  [INPUT_SIZES.SMALL]: "w-3xl",
};

type SearchInputPropsType = {
  size?: InputSizeType;
  placeholder?: string;
  value?: string;
  onChange: (value: string) => void;
  onSearchActive?: () => void;
  timeout?: number;
  activeClassName?: string;
};

const SearchInput = ({
  size = INPUT_SIZES.NORMAL,
  placeholder = "",
  value = "",
  onChange,
  onSearchActive = () => {},
  timeout = 100,
  activeClassName = "",
}: SearchInputPropsType) => {
  const timer = useRef(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [active, setActive] = useState<boolean>(!!value);

  const handleChange = () => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(() => {
      onChange(inputRef.current?.value || "");
    }, timeout);
  };

  useEffect(() => {
    if (active) {
      onSearchActive();
    }
  }, [active]);

  const classes: string = `peer w-full h-full rounded-lg outline-0 font-manrope font-medium text-xs text-primary border border-solid placeholder:text-secondary placeholder:opacity-100 focus:bg-primary focus:border-brand ${
    active
      ? "pl-4xl pr-md cursor-initial bg-primary border-primary"
      : "bg-accent border-transparent"
  }`;

  return (
    <div
      className={`group relative cursor-pointer transition-all duration-300 ${
        SIZE_HEIGHT_STYLES[size]
      } ${active ? activeClassName || "w-52" : SIZE_WIDTH_STYLES[size]}`}
      onClick={() => {
        setActive(true);
        setTimeout(() => {
          inputRef.current?.focus();
        }, 0);
      }}
      onBlur={() => {
        if (inputRef.current?.value) return;
        setActive(false);
      }}
    >
      <input
        ref={inputRef}
        className={classes}
        disabled={!active}
        placeholder={active ? placeholder : ""}
        onChange={handleChange}
        defaultValue={value}
      />
      <MagnifyingGlass
        size={16}
        className={`absolute top-1/2 ${
          active ? "left-xl" : "left-1/2"
        } cursor-pointer text-secondary peer-focus:text-primary group-hover:text-primary -translate-x-1/2 -translate-y-1/2`}
      />
    </div>
  );
};

export default SearchInput;
