import Label, { LABEL_SIZES } from "@shared/ui/Label";
import React from "react";
import { TabsComponentPropsType, TabType } from "Tabs";

const PillTabs = ({
  tabs,
  onTabChange,
  className = "",
}: TabsComponentPropsType) => (
  <div className={`flex items-center gap-md flex-wrap ${className || ""}`}>
    {tabs.map(({ id, icon, label, rightContent, isActive }: TabType) => (
      <button
        key={id}
        className={`group flex items-center border-0 outline-0 cursor-pointer px-sm py-xs ${
          isActive ? "bg-brand-lightest rounded" : "bg-transparent"
        }`}
        onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          event.stopPropagation();
          onTabChange(id);
        }}
      >
        {icon && (
          <div
            className={`mr-2xs w-lg h-lg ${
              isActive
                ? "text-primary"
                : "text-tertiary group-hover:text-secondary"
            }`}
          >
            {icon}
          </div>
        )}

        <Label
          size={LABEL_SIZES.SMALL}
          color={
            isActive
              ? "text-primary"
              : "text-tertiary group-hover:text-secondary"
          }
        >
          {label}
        </Label>

        {rightContent && <div className="ml-2xs">{rightContent}</div>}
      </button>
    ))}
  </div>
);

export default PillTabs;
