import { BREADCRUMB_DEFAULT_TEXT_LIMIT } from "@shared/constants/index";
import Label, { LABEL_SIZES } from "@shared/ui/Label";
import { trimText } from "@shared/utils/textFormatting";
import React, { Fragment, useMemo } from "react";
import { Link } from "react-router-dom";

type OptionType = {
  label: string;
  link?: string;
  state?: { [key: string]: string | boolean | number };
};

type BreadcrumbsPropsType = {
  options: OptionType[];
  textLimit?: number;
};

const Breadcrumbs = ({
  options,
  textLimit = BREADCRUMB_DEFAULT_TEXT_LIMIT,
}: BreadcrumbsPropsType) => {
  const count: number = options.length;

  const updatedOptions: OptionType[] = useMemo(() => {
    if (count > 3) {
      return [
        options[0],
        { label: "..." },
        options[count - 2],
        options[count - 1],
      ];
    }
    return options;
  }, [options, count]);

  return (
    <div className="flex items-center py-2xs overflow-hidden whitespace-nowrap max-md:pr-md">
      {updatedOptions.map((option: OptionType, index: number) => (
        <Fragment key={`bread-crumb-${index}`}>
          {option.link ? (
            <Link
              to={{
                pathname: option.link,
                ...(option.state ? { state: option.state } : {}),
              }}
            >
              <Label size={LABEL_SIZES.SMALL} color="text-secondary">
                {trimText(option.label, textLimit)}
              </Label>
            </Link>
          ) : (
            <Label
              size={LABEL_SIZES.SMALL}
              title={option.label}
              className="truncate"
            >
              {trimText(option.label, textLimit)}
            </Label>
          )}
          {index < updatedOptions.length - 1 && (
            <Label
              size={LABEL_SIZES.SMALL}
              color="text-secondary"
              className="mx-sm"
            >
              /
            </Label>
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default Breadcrumbs;
