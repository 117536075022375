import Label, { LABEL_SIZES } from "@shared/ui/Label";
import React from "react";
import { TabsComponentPropsType, TabType } from "Tabs";

const UnderlineTabs = ({
  tabs,
  onTabChange,
  className = "",
}: TabsComponentPropsType) => (
  <div
    className={`w-full border-b border-t-0 border-x-0 border-solid border-primary ${
      className || ""
    }`}
  >
    <ul className="flex items-center m-0 p-0 list-none gap-2xs">
      {tabs.map(({ id, label, rightContent, isActive }: TabType) => (
        <li key={id} className="group flex flex-col">
          <button
            className="flex items-center border-0 outline-0 cursor-pointer px-sm py-md bg-transparent"
            onClick={(
              event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
            ) => {
              event.stopPropagation();
              onTabChange(id);
            }}
          >
            <Label
              size={LABEL_SIZES.SMALL}
              color={
                isActive
                  ? "text-primary"
                  : "text-tertiary group-hover:text-secondary"
              }
              className="transition-all duration-200 whitespace-nowrap"
            >
              {label}
            </Label>

            {rightContent && (
              <div
                className={`ml-2xs font-manrope font-bold text-xs ${
                  isActive
                    ? "text-primary"
                    : "text-tertiary group-hover:text-secondary"
                }`}
              >
                {rightContent}
              </div>
            )}
          </button>
          <div
            className={`w-full h-3xs rounded-t ${
              isActive
                ? "bg-brand-dark"
                : "bg-transparent group-hover:bg-transparent"
            } transition-all duration-200`}
          ></div>
        </li>
      ))}
    </ul>
  </div>
);

export default UnderlineTabs;
