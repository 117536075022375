import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";

import ContentLoading from "~/components/ContentLoading";
import { useAuth } from "~/components/general";
import PartDocuments from "~/components/parts/_partDocuments";
import UnderlineTabsWrapper from "~/components/UnderlineTabsWrapper";
import { getCustomerInventoryPart } from "~/services";
import { getPartThumbnail } from "~/utils";

const POLLING_CONFIG = {
  MAX_INTERVALS: 5,
  TIMEOUT: 5000,
};

const PartsPreview = ({ data }) => {
  const [intervalCount, setIntervalCount] = useState(0);
  const { messages } = useIntl();
  const { user } = useAuth();

  const { inventoryPart, refetch } = getCustomerInventoryPart(data?._id);

  useEffect(() => {
    if (
      intervalCount === POLLING_CONFIG.MAX_INTERVALS ||
      inventoryPart?.documentFolders?.externalId
    )
      return;

    const intervalId = setInterval(() => {
      refetch();
      setIntervalCount((prevCount) => prevCount + 1);
    }, POLLING_CONFIG.TIMEOUT);

    return () => {
      clearInterval(intervalId);
    };
  }, [intervalCount]);

  return (
    <>
      <UnderlineTabsWrapper
        useHistoryState={false}
        tabs={[
          {
            id: "part-details",
            label: messages?.parts?.partDetails,
            content: (
              <section>
                <div className="u-flex u-margin-b-4">
                  <div className="u-width-50 u-padding-r-2">
                    <div className="parts-preview__label">
                      {messages?.parts?.labels.name}:
                    </div>
                    <div className="text-elipsis parts-preview__detail-text">
                      {data.name}
                    </div>
                  </div>
                  <div className="u-width-50">
                    <div className="parts-preview__label">
                      {messages?.parts?.labels.articleNumber}:
                    </div>
                    <div className="text-elipsis parts-preview__detail-text">
                      {data.articleNumber}
                    </div>
                  </div>
                </div>
                <div className="u-margin-b-4">
                  <div className="parts-preview__label">
                    {messages?.parts?.labels.description}:
                  </div>
                  <div className="parts-preview__detail-text">
                    {data.description}
                  </div>
                </div>
                {data.image && (
                  <div>
                    <div className="parts-preview__img-container">
                      <img
                        src={getPartThumbnail(data, true)}
                        className="parts-preview__img"
                      />
                    </div>
                  </div>
                )}
              </section>
            ),
          },
          {
            id: "part-documentation",
            label: messages?.parts.documentation,
            content: !inventoryPart?.documentFolders?.externalId ? (
              <ContentLoading />
            ) : (
              <PartDocuments
                userFolderToken={user?.foldersAccessToken}
                part={inventoryPart}
              />
            ),
          },
        ]}
      />
    </>
  );
};

export default PartsPreview;
