import { Input } from "@shared/ui/Inputs";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

import { COLOR } from "#/src/constants/colors";

const LoginForm = ({
  modifier,
  callback = () => {},
  intl,
  color,
  isLighterBackground,
  buttonStyle,
}) => {
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const { email, password } = watch();

  const onSubmit = (data) => {
    callback(data, setLoading);
  };

  const onError = () => {
    setLoading(false);
  };

  const emailField = register("email", { required: true });

  const passwordField = register("password", { required: true });

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)} className="form">
      <Input
        keyId="email"
        label={intl.messages?.login?.form.email.label}
        name="email"
        type="text"
        placeholder={intl.messages?.login?.form.email.placeholder}
        {...emailField}
        setRef={emailField.ref}
        errorMessage={errors?.email && intl.messages?.login?.form.email.error}
        customInputClasses={
          errors?.email
            ? "bg-primary border-danger focus:bg-primary focus:border-danger"
            : email
            ? "bg-primary border-primary"
            : "bg-accent border-transparent"
        }
      />
      <div className="mt-2xl mb-md">
        <Input
          keyId="password"
          label={intl.messages?.login?.form.password.label}
          name="password"
          type="password"
          placeholder={intl.messages?.login?.form.password.placeholder}
          {...passwordField}
          setRef={passwordField.ref}
          errorMessage={
            errors?.password && intl.messages?.login?.form.password.error
          }
          customInputClasses={
            errors?.password
              ? "bg-primary border-danger focus:bg-primary focus:border-danger"
              : password
              ? "bg-primary border-primary"
              : "bg-accent border-transparent"
          }
        />
      </div>
      <div className="form-group auth-formGroup auth-forgot-password">
        <Link
          to={`/${intl?.locale}/forgot-password${
            modifier ? "/" + modifier : ""
          }`}
          style={{ color: !isLighterBackground ? color : COLOR.$gray_v2_100 }}
        >
          {intl.messages?.common?.forgotPassword}
        </Link>
      </div>
      <div className="form-group auth-submit">
        <button
          className={`c-button c-button--primary ${
            loading && "c-button--disabled"
          }`}
          type="submit"
          style={{ background: color, ...buttonStyle }}
          onClick={() => {
            setLoading(true);
            handleSubmit(onSubmit, onError);
          }}
        >
          {loading
            ? intl.messages?.login?.form.submit.loading
            : intl.messages?.login?.form.submit.default}
        </button>
      </div>
    </form>
  );
};

export default LoginForm;
