import {
  GET_OWN_FACILITY_TICKET_BY_ID,
  LIST_OWN_CUSTOMER_TICKETS,
} from "../api";
import client from "../apollo/_client";
import { ITEMS_PER_PAGE } from "../constants/global";
import { useGetTicketByIdForList } from "../services";

const useReorderWorkOrderList = () => {
  const { getTicketById } = useGetTicketByIdForList();

  const variables = {
    params: {
      limit: ITEMS_PER_PAGE,
      skip: 0,
      where: {
        myClosedWorkOrders: false,
      },
    },
  };

  const reOrderWithinList = ({ allTickets, position, status }) => {
    client.cache.updateQuery(
      {
        query: LIST_OWN_CUSTOMER_TICKETS,
        variables,
      },
      ({ listOwnCustomerTickets, ...rest }) => {
        const tickets = [...(listOwnCustomerTickets?.tickets || allTickets)];
        const ticket = {
          ...tickets[position],
          status: status || tickets[position].status,
        };
        tickets.splice(position, 1);

        return {
          ...rest,
          listOwnCustomerTickets: {
            ...listOwnCustomerTickets,
            tickets: [ticket, ...tickets],
          },
        };
      },
    );
  };

  const removeFromList = ({ allTickets, variables, position }) => {
    client.cache.updateQuery(
      {
        query: LIST_OWN_CUSTOMER_TICKETS,
        variables,
      },
      ({ listOwnCustomerTickets, ...rest }) => {
        const tickets = [...(listOwnCustomerTickets?.tickets || allTickets)];
        tickets.splice(position, 1);
        return {
          ...rest,
          listOwnCustomerTickets: {
            ...listOwnCustomerTickets,
            tickets,
          },
        };
      },
    );
  };

  const appendTicketOnTop = ({ id, allTickets, isNewTicket }) => {
    getTicketById(id).then(
      ({ data: { getOwnFacilityTicketById: ticket } = {} }) => {
        if (!ticket) return;

        client.cache.updateQuery(
          {
            query: LIST_OWN_CUSTOMER_TICKETS,
            variables,
          },
          ({ listOwnCustomerTickets, ...rest }) => {
            const tickets = [
              ...(listOwnCustomerTickets?.tickets || allTickets),
            ];
            let newTicketList = tickets;
            if (tickets[0]._id !== id) {
              newTicketList = [ticket, ...newTicketList];
            }

            return {
              ...rest,
              listOwnCustomerTickets: {
                ...listOwnCustomerTickets,
                ...(isNewTicket && {
                  totalCount: listOwnCustomerTickets.totalCount + 1,
                }),
                tickets: newTicketList,
              },
            };
          },
        );
      },
    );
  };

  const handleReorderTicket = ({
    allTickets,
    id,
    isNewTicket,
    isTicketDeleted,
    status,
  }) => {
    const index = allTickets.findIndex((ticket) => ticket._id === id);

    // work order is already on top

    if (index === 0 && !isTicketDeleted) {
      // work order is on top but status is updated
      if (status) {
        reOrderWithinList({ position: index, allTickets, variables, status });
      }
      return;
    }

    if (isTicketDeleted && index >= 0) {
      removeFromList({
        position: index,
        allTickets,
        variables,
      });
      return;
    }

    // work order exists somewhere in the list
    if (index > 0) {
      reOrderWithinList({ position: index, allTickets });

      return;
    }

    // work order does not exist in the list
    appendTicketOnTop({ id, allTickets, isNewTicket });
  };

  const handleUpdateTicketStatus = ({ status, id }) => {
    const { getOwnFacilityTicketById } = client.cache.readQuery({
      query: GET_OWN_FACILITY_TICKET_BY_ID,
      variables: {
        id,
      },
    });
    client.cache.writeQuery({
      query: GET_OWN_FACILITY_TICKET_BY_ID,
      variables: {
        id: id,
      },
      data: {
        getOwnFacilityTicketById: {
          ...getOwnFacilityTicketById,
          status,
        },
      },
    });
  };

  return { handleReorderTicket, handleUpdateTicketStatus };
};

export default useReorderWorkOrderList;
