import React, { Fragment } from "react";
import { TabsComponentPropsType, TabType } from "Tabs";

const SegmentTabs = ({
  tabs,
  onTabChange,
  className = "",
}: TabsComponentPropsType) => (
  <div
    className={`rounded border border-solid border-primary flex ${
      className || ""
    }`}
  >
    {tabs.map((tab: TabType, index: number) => (
      <Fragment key={tab.id}>
        <button
          className={`font-manrope font-medium text-xs border-0 outline-0 cursor-pointer p-sm ${
            tab.isActive
              ? "text-primary bg-brand-lightest rounded"
              : "text-secondary bg-transparent"
          }`}
          onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            event.stopPropagation();
            onTabChange(tab.id);
          }}
        >
          {tab.label}
        </button>
        {index < tabs.length - 1 && (
          <>
            {tab.isActive || tabs[index + 1].isActive ? (
              <div className="w-2xs"></div>
            ) : (
              <div className="border-l border-r-0 border-y-0 border-solid border-primary mx-2xs"></div>
            )}
          </>
        )}
      </Fragment>
    ))}
  </div>
);

export default SegmentTabs;
