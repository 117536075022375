import React from "react";

import PillTabs from "./PillTabs";
import SegmentTabs from "./SegmentTabs";
import UnderlineTabs from "./UnderlineTabs";

export const TAB_VARIANTS = {
  UNDERLINE: "underline",
  PILLS: "pills",
  SEGMENT: "segment",
} as const;

type TabVariantType = (typeof TAB_VARIANTS)[keyof typeof TAB_VARIANTS];

export type TabType = {
  id: string;
  icon?: React.JSX.Element;
  label: string;
  rightContent?: React.ReactNode;
  isActive: boolean;
};

type TabsPropsType = {
  variant?: TabVariantType;
  tabs: TabType[];
  onTabChange: (tabId: string) => void;
  className?: string;
};

export type TabsComponentPropsType = {
  tabs: TabType[];
  onTabChange: (tabId: string) => void;
  className?: string;
};

const Tabs = ({
  variant = TAB_VARIANTS.UNDERLINE,
  tabs,
  onTabChange,
  className = "",
}: TabsPropsType) => {
  let Component: (props: TabsComponentPropsType) => React.JSX.Element;

  switch (variant) {
    case TAB_VARIANTS.UNDERLINE:
      Component = UnderlineTabs;
      break;
    case TAB_VARIANTS.PILLS:
      Component = PillTabs;
      break;
    case TAB_VARIANTS.SEGMENT:
      Component = SegmentTabs;
  }
  return (
    <Component tabs={tabs} onTabChange={onTabChange} className={className} />
  );
};

export default Tabs;
