import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import mixpanel from "mixpanel-browser";
import PubNub from "pubnub";
import React from "react";
import { useHistory } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { CURRENT_USER_QUERY, LOGIN_MUTATION } from "~/api";
import { APP } from "~/apollo/_config";
import { languageAtom } from "~/LocaleHanlder";
import { base64String, isTrackingDisabled, saveLocaleToStorage } from "~/utils";
import { isMixpanelEnabled } from "~/utils/_mixpanel";

const { ROUTES: { FACILITY_LOGIN: LOGIN } = {} } = APP;

const isFunction = (functionToCheck) =>
  functionToCheck && {}.toString.call(functionToCheck) === "[object Function]";

let chat;

//  pollInterval: CHAT_TOKEN_VALIDITY_IN_MINUTES.VALUE * 1000 * 60 - 1000 * 45 * 5 > 1
//     ? CHAT_TOKEN_VALIDITY_IN_MINUTES.VALUE * 1000 * 60 - 1000 * 45 * 5
//     : 1000 * 45 * 1, // poll before expiration in any case

const useAuth = (intl) => {
  const history = useHistory();
  const languageCoil = useRecoilValue(languageAtom);
  const {
    data: { currentUser } = {},
    loading,
    refetch,
  } = useQuery(CURRENT_USER_QUERY);
  const [submitLogin] = useMutation(LOGIN_MUTATION);
  const client = useApolloClient();

  React.useEffect(() => {
    if (!chat && currentUser?.chatKeys && currentUser.chatUUID) {
      chat = new PubNub({
        ...currentUser.chatKeys,
        uuid: currentUser.chatUUID,
        suppressLeaveEvents: true,
      });
    }
    const { chatToken } = currentUser || {};
    const validToken = chat?._tokenManager?._token === chatToken;
    if (!validToken && chatToken) {
      chat?.setToken?.(chatToken);
    }

    // if (chatUUIDMetadata) {
    //   chat?.setUUID?.(chatUUIDMetadata.data?.custom?.uuid);
    //   chat?.objects?.setUUIDMetadata?.(chatUUIDMetadata);
    // }
  }, [
    currentUser?.chatKeys,
    loading,
    currentUser?.chatUUID,
    currentUser?.chatToken,
  ]);

  if (loading) return <p>Loading...</p>;

  const oemUrl = currentUser?.oem?.urlOem?.split("/");
  let loginURL = "/" + intl?.locale + LOGIN;

  if (oemUrl) {
    loginURL = `/${intl?.locale}${LOGIN}/${oemUrl[oemUrl?.length - 1]}`;
  }

  const logout = (redirect = false) => {
    const trackingDisabled = isTrackingDisabled();
    const currentPath = window.location.pathname;
    const encodedCurrentPath = encodeURIComponent(currentPath);
    if (redirect === true) {
      loginURL = `${loginURL.replace(
        /\/$/,
        "",
      )}/?redirect=${encodedCurrentPath}`;
    }

    localStorage.clear();

    if (isMixpanelEnabled()) {
      mixpanel.reset();
    }

    sessionStorage.clear();

    client.clearStore().then(() => {
      chat = undefined;
      if (languageCoil?.lang) saveLocaleToStorage(languageCoil?.lang);
      if (trackingDisabled) window.location.assign(loginURL);
      else history.push(loginURL);
    });
  };

  const login = ({ email, username, password, organizationId }, callback) => {
    submitLogin({
      variables: {
        userCredentials: {
          username: email?.toLowerCase() || username?.toLowerCase(),
          password: base64String(password),
          organization: organizationId,
        },
      },
      refetchQueries: [
        {
          query: CURRENT_USER_QUERY,
        },
      ],
    })
      .then((res) => {
        if (!!callback && isFunction(callback)) {
          callback(null, res);
        } else history.push("/");
      })
      .catch((e) => {
        // notify error
        // console.log(e)
        if (!!callback && isFunction(callback)) callback(e);
      });
  };
  return {
    user: currentUser,
    refetch,
    chat,
    loading,
    login,
    logout,
    isLoggedIn: !!currentUser && !!Object.keys(currentUser).length,
  };
};

export default useAuth;
