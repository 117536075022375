import React from "react";

export const POSITIONS = {
  BETWEEN: "between",
  CENTER: "center",
  END: "end",
  START: "start",
} as const;

export type PositionType = (typeof POSITIONS)[keyof typeof POSITIONS];

const POSITION_TO_CLASS_MAP: Record<PositionType, string> = {
  [POSITIONS.BETWEEN]: "justify-between",
  [POSITIONS.CENTER]: "justify-center",
  [POSITIONS.END]: "justify-end",
  [POSITIONS.START]: "justify-start",
};

type AppHeaderPropsType = {
  background?: string;
  borderColor?: string;
  contentPosition?: PositionType;
  children: React.ReactNode;
  padding?: string;
  className?: string;
};

const AppHeader = ({
  background = "bg-primary",
  borderColor = "border-primary",
  contentPosition = POSITIONS.BETWEEN,
  children,
  padding = "py-lg",
  className = "",
  ...props
}: AppHeaderPropsType & React.ComponentPropsWithoutRef<"div">) => (
  <div
    className={`sticky top-0 z-[5] flex items-center ${POSITION_TO_CLASS_MAP[contentPosition]} ${padding} ${background} border-0 border-b border-solid ${borderColor} ${className}`}
    {...props}
  >
    {children}
  </div>
);

export default AppHeader;
