import { useQuery } from "@apollo/client";
import Headline, { HEADLINE_SIZES } from "@shared/ui/Headline";
import Toast, { TOAST_TYPES } from "@shared/ui/Toast";
import mixpanel from "mixpanel-browser";
import React, { useMemo } from "react";
import { injectIntl } from "react-intl";
import { Redirect, useParams, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import { LoginForm as Form } from "./form";
import { useAuth } from "./general";
import Loading from "./general/_loading";
import { GET_OEM_BY_QUERY } from "../api";
import { COLOR } from "../constants/colors";

import PAID_FEATURES from "$/settings/paid-features.json";
import { LOGIN, LOGIN_FAILED } from "~/constants/global";
import { useBrandingProperties } from "~/hooks";
import { getEnums } from "~/utils";
import { isMixpanelEnabled } from "~/utils/_mixpanel";

const paidFeatures = getEnums(PAID_FEATURES, "reference");

const Login = ({ intl }) => {
  const { modifier } = useParams();
  const { search } = useLocation();

  const {
    data: { getOemBySlug: oem } = {},
    loading,
    error,
  } = useQuery(GET_OEM_BY_QUERY, {
    variables: {
      slug: modifier,
    },
  });

  const auth = useAuth();
  const { isBrandingEnable, isLighterBackground, branding, buttonStyle } =
    useBrandingProperties(oem, intl);
  const [redirect, setRedirect] = React.useState(false);

  const colorStyles = useMemo(() => {
    return isLighterBackground ? { color: COLOR.$gray_v2_100 } : {};
  }, [isLighterBackground]);

  // if qrc and valid oem path facilitylogin mutation
  if (redirect) {
    const redirectTo = new URLSearchParams(search).get("redirect");
    let pathname = redirectTo ? decodeURIComponent(redirectTo) : "/en/app";

    if (!pathname.startsWith("/")) {
      pathname = "/" + pathname;
    }

    return (
      <Redirect
        to={{
          pathname,
          state: {
            welcome: true,
          },
        }}
      />
    );
  }

  const isCustomerPortalPaid = !oem
    ? true
    : oem?.paidFeatures.includes(paidFeatures.customerPortal);

  if (error) {
    if (typeof modifier !== "undefined") {
      return (
        <Redirect
          to={{
            pathname: "/error-page/404",
          }}
        />
      );
    }
  }

  const authFailureCallback = () =>
    toast(
      <Toast
        message={intl.messages?.login?.wrongCreds}
        type={TOAST_TYPES.ERROR}
      />,
      {
        closeButton: false,
      },
    );

  var renderPage = () => {
    return (
      <div className="auth">
        <div
          className=" login-left-wrapper"
          style={{
            background: branding?.backgroundColor,
          }}
        >
          {!isBrandingEnable && <div className="login-footer-copy" />}
          <div className="login-hero-container">
            <div
              className={`login-hero-content ${
                !isBrandingEnable || (!branding?.brandLogo && isBrandingEnable)
                  ? "u-justify-center"
                  : "u-justify-start"
              }`}
            >
              {isBrandingEnable && branding?.brandLogo && (
                <div className="login-brand-logo">{branding?.brandLogo}</div>
              )}
              <Headline
                size={HEADLINE_SIZES.X_LARGE}
                color={isLighterBackground ? "text-primary" : "text-inverse"}
                className="break-words mb-sm max-md:text-lg"
              >
                {branding?.heading}
              </Headline>
              <Headline
                size={HEADLINE_SIZES.X_LARGE}
                color={isLighterBackground ? "text-primary" : "text-inverse"}
                className="mb-4xl font-extralight break-words max-md:text-lg max-md:mb-lg"
              >
                {branding?.subHeading}
              </Headline>
              <p className="login-hero-subtitle" style={colorStyles}>
                {branding?.paragraph}
              </p>
            </div>
          </div>

          {!isBrandingEnable && (
            <div
              className={`login-footer ${
                isBrandingEnable && oem?.backgroundColor ? "has-branding" : ""
              }`}
            >
              {branding?.brandLogo}
            </div>
          )}
        </div>

        <div className="auth-form-wrapper">
          <Form
            modifier={modifier}
            color={branding?.backgroundColor}
            isLighterBackground={isLighterBackground}
            buttonStyle={buttonStyle}
            intl={intl}
            callback={(values, loading) => {
              const { email: userEmail } = values;
              if (!isCustomerPortalPaid) {
                loading(false);
                authFailureCallback();
                return;
              }
              if (isMixpanelEnabled()) {
                mixpanel.track(LOGIN, {
                  oem: window.location.pathname
                    .split("/")
                    .slice(-1)
                    .join("")
                    .split("?")[0],
                  userEmail,
                });
              }

              auth.login(
                {
                  ...values,
                  organizationId: oem?._id,
                },
                (e) => {
                  loading(false);
                  if (e) {
                    authFailureCallback();
                    if (isMixpanelEnabled()) {
                      mixpanel.track(LOGIN_FAILED, {
                        oem: window.location.pathname
                          .split("/")
                          .slice(-1)
                          .join("")
                          .split("?")[0],
                        userEmail,
                      });
                    }
                  } else {
                    setRedirect(true);
                  }
                },
              );
            }}
          />
        </div>
      </div>
    );
  };

  if (loading) return <Loading />;

  return renderPage();
};

export default injectIntl(Login);
