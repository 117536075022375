import { Input } from "@shared/ui/Inputs";
import React, { useState } from "react";
import { useForm } from "react-hook-form";

const ForgotPasswordForm = ({
  callback = () => {},
  intl,
  color,
  isLighterBackground,
  buttonStyle,
}) => {
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const { email } = watch();

  const onSubmit = (data) => {
    callback(data, setLoading);
  };

  const onError = () => {
    setLoading(false);
  };

  const emailField = register("email", { required: true });

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)} className="form">
      <Input
        keyId="email"
        label={intl.messages?.forgotPassword?.form.email.label}
        name="email"
        type="text"
        placeholder={intl.messages?.forgotPassword?.form.email.placeholder}
        {...emailField}
        setRef={emailField.ref}
        errorMessage={
          errors?.email && intl.messages?.forgotPassword?.form.email.error
        }
        customInputClasses={
          errors?.email
            ? "bg-primary border-danger focus:bg-primary focus:border-danger"
            : email
            ? "bg-primary border-primary"
            : "bg-accent border-transparent"
        }
      />
      <div className="form-group auth-submit">
        <button
          style={{ background: color, ...buttonStyle }}
          className={`c-button c-button--primary ${
            loading && "c-button--disabled"
          }`}
          type="submit"
          onClick={() => {
            handleSubmit(onSubmit, onError);
          }}
        >
          {loading
            ? intl.messages?.forgotPassword?.form.submit.loading
            : intl.messages?.forgotPassword?.form.submit.default}
        </button>
      </div>
    </form>
  );
};

export default ForgotPasswordForm;
