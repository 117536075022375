import { getAllRoles } from "@shared/utils/getAllRoles";
import { debounce } from "lodash-es";
import { CaretDoubleLeft, CaretDoubleRight, Gear } from "phosphor-react";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { atom, useRecoilState } from "recoil";

import PAID_FEATURES from "$/settings/paid-features.json";
import { unreadChannelMessagesAtom } from "~/atoms/_chat.jsx";
import Avatar from "~/components/_avatar";
import UserOptions from "~/components/_userOptions";
import AppVersion from "~/components/_version";
import { documentFoldersListener } from "~/components/DocumentFoldersListener";
import useAuth from "~/components/general/_use-auth";
import { SMALL_DEVICE_WIDTH, DOCUMENT_FOLDERS } from "~/constants/global";
import {
  MEMBERSHIP_UPDATED_IDENTIFIER,
  WORK_ORDER_BROADCAST_TOPICS,
  WORK_ORDER_CREATED_IDENTIFIER,
  WORK_ORDER_DELETED_IDENTIFIER,
  WORK_ORDER_UPDATED_IDENTIFIER,
} from "~/constants/workOrders";
import useNotification from "~/hooks/_useNotification";
import useWindowDimensions from "~/hooks/_useWindowDimensions";
import { LogoutIcon } from "~/icons";
import { getEnums } from "~/utils";
import { broadcaster } from "~/utils/_appBroadcast";

const ROLES = getAllRoles();
const DEBOUNCE_PUBLISH_WAIT = 5000; // 5 seconds;
const paidFeatures = getEnums(PAID_FEATURES, "reference");

export const navigationAtom = atom({
  key: "navigationOptions",
  default: {
    isCollapsed: false,
    isResponsiveCollapsed: window.innerWidth <= SMALL_DEVICE_WIDTH,
  },
});

const Navigation = ({ intl, routes }) => {
  const { width: deviceWidth } = useWindowDimensions();
  const channelsRef = useRef({});
  const currentPathRef = useRef("");
  const { pathname } = useLocation();
  const pathMatcher = /^.*\/app\/work-orders\/(?<currentTicketId>.+)/;
  const matchedPath = pathname?.match(pathMatcher)?.groups;
  const [navigationOptions, setNavigationOptions] =
    useRecoilState(navigationAtom);
  const [unreadChannelMessages, setUnreadChannelMessages] = useRecoilState(
    unreadChannelMessagesAtom,
  );

  const path = pathname === "/app" ? routes[0].path : pathname;

  const { user, chat: chatInstance, logout } = useAuth(intl);
  const { unreadLabels } = useNotification();

  const isGMU = useMemo(() => user?.role === ROLES.GMU.VALUE, [user?.role]);

  const debouncePublishMembershipUpdated = debounce(() => {
    broadcaster.publish(WORK_ORDER_BROADCAST_TOPICS.MEMBERSHIP_UPDATED);
  }, DEBOUNCE_PUBLISH_WAIT);

  const toggleSidebar = () => {
    if (deviceWidth > SMALL_DEVICE_WIDTH) {
      setNavigationOptions({
        ...navigationAtom,
        isCollapsed: !navigationOptions.isCollapsed,
      });
    } else {
      setNavigationOptions({
        ...navigationAtom,
        isCollapsed: false,
        isResponsiveCollapsed: !navigationOptions.isResponsiveCollapsed,
      });
    }
  };

  const handleNewMessageNotification = useCallback(
    async (message) => {
      const existingChan = channelsRef.current?.[message?.channel];
      const currentUserIsPublisher = user?.chatUUID === message?.publisher;

      if (!currentUserIsPublisher && existingChan !== undefined) {
        setUnreadChannelMessages({
          channels: {
            ...channelsRef.current,
            [message.channel]: existingChan + 1,
          },
        });
      }
    },
    [unreadChannelMessages],
  );

  const handleOrganizationEvents = async (message) => {
    if (
      (message?.userMetadata?.isMachine ||
        message?.userMetadata?.isProductionLine ||
        message?.userMetadata?.isComponent) &&
      message?.message?.text === DOCUMENT_FOLDERS
    ) {
      return documentFoldersListener(message);
    }

    if (
      message?.userMetadata?.isTicket &&
      (message?.message?.text === WORK_ORDER_UPDATED_IDENTIFIER ||
        message?.message?.text === WORK_ORDER_CREATED_IDENTIFIER)
    ) {
      const { oemId, customerId, ticketId, ticketStatus } =
        message.message.payload;
      broadcaster.publish(
        WORK_ORDER_BROADCAST_TOPICS.REORDER_WORK_ORDER_LIST,
        ticketId,
        message?.message?.text === WORK_ORDER_CREATED_IDENTIFIER,
        false,
        ticketStatus,
      );
      // Add channel to unreadChannelMessages
      const ticketChannel = `channel-${oemId}-${customerId}-${ticketId}`;
      if (channelsRef.current?.[ticketChannel] === undefined)
        setUnreadChannelMessages({
          channels: {
            ...channelsRef.current,
            [ticketChannel]: 0,
          },
        });
    }

    if (
      message?.userMetadata?.isTicket &&
      message?.message?.text === WORK_ORDER_DELETED_IDENTIFIER
    ) {
      const { ticketId } = message.message.payload;
      broadcaster.publish(
        WORK_ORDER_BROADCAST_TOPICS.REORDER_WORK_ORDER_LIST,
        ticketId,
        false,
        true,
      );
    }

    if (
      message?.userMetadata?.isTicket &&
      message?.message?.text === MEMBERSHIP_UPDATED_IDENTIFIER &&
      user?.chatUUID &&
      message?.message?.payload?.affectedMembers?.includes(user.chatUUID)
    ) {
      debouncePublishMembershipUpdated();
    }
  };

  useEffect(() => {
    if (unreadChannelMessages?.channels) {
      channelsRef.current = unreadChannelMessages?.channels;
    }
    if (location) {
      currentPathRef.current = matchedPath?.currentTicketId;
    }
  }, [unreadChannelMessages, location?.key]);

  useEffect(() => {
    if (
      user &&
      user.oem &&
      !user?.oem.paidFeatures.includes(paidFeatures.customerPortal)
    ) {
      logout();
    }
  }, [user]);

  useEffect(() => {
    const listener = {
      message: handleNewMessageNotification,
      file: handleNewMessageNotification,
    };
    const organizationEventListener = { message: handleOrganizationEvents };

    chatInstance?.subscribe({
      channelGroups: [user?.notificationChannelGroupName],
      withPresence: false,
    });
    chatInstance?.subscribe({
      channels: [user?.organizationNotificationChannel],
      withPresence: false,
    });

    chatInstance?.addListener(listener);
    chatInstance?.addListener(organizationEventListener);

    return () => {
      chatInstance?.unsubscribe({
        channelGroups: [user?.notificationChannelGroupName],
      });
      chatInstance?.unsubscribe({
        channels: [user?.organizationNotificationChannel],
      });

      chatInstance?.removeListener(listener);
      chatInstance?.removeListener(organizationEventListener);
    };
  }, [
    user?.notificationChannelGroupName,
    user?.chatToken,
    user?.organizationNotificationChannel,
  ]);

  React.useEffect(() => {
    setNavigationOptions({
      ...navigationOptions,
      isCollapsed: false,
      isResponsiveCollapsed: deviceWidth <= SMALL_DEVICE_WIDTH,
    });
  }, [deviceWidth]);

  const closeMenu = () => {
    setNavigationOptions({
      ...navigationOptions,
      isCollapsed: false,
      isResponsiveCollapsed: deviceWidth <= SMALL_DEVICE_WIDTH,
    });
  };

  if (isGMU) return null;

  return !navigationOptions?.isCollapsed ? (
    <nav
      className={`navigation ${
        navigationOptions?.isResponsiveCollapsed ? "mobile-collapsed" : ""
      }`}
    >
      <div className="menu-toggler" onClick={toggleSidebar}>
        {navigationOptions.isCollapsed ? (
          <CaretDoubleRight size={16} />
        ) : (
          <CaretDoubleLeft size={16} />
        )}
      </div>
      <UserOptions user={user} logout={logout} />

      <div className="navigation-content navigation-content-v2">
        <ul className="navigation-list">
          {routes.map((route) => {
            return (
              route.showInNavigation && (
                <li key={route.path} className="navigation-item">
                  <NavLink
                    onClick={closeMenu}
                    to={`/${intl.locale}${route.path}`}
                    className="navigation-link"
                    activeClassName="is-navigation-link--active"
                  >
                    {route.icon}
                    {route.iconActive}
                    {intl.messages?.navigation?.[route.label]}
                    {unreadLabels[route.key] &&
                    unreadLabels[route.key] !== 0 ? (
                      <span className="navigation-linkBadge">
                        {unreadLabels[route.key]}
                      </span>
                    ) : (
                      ""
                    )}
                  </NavLink>
                </li>
              )
            );
          })}
        </ul>
      </div>
      <div className="navigation-settings">
        <NavLink
          onClick={closeMenu}
          to={`/${intl?.locale}/app/settings`}
          className="navigation-link u-margin-b-2 "
          activeClassName="is-navigation-link--active"
        >
          <Gear className="icon" size={20} />
          <Gear className="icon-active" size={20} weight="fill" />
          {intl?.messages?.navigation?.settings}
        </NavLink>
        <div onClick={logout} className="navigation-link">
          <LogoutIcon width="20" />
          <span className="tooltip-msg">{intl?.messages?.login?.signOut}</span>
        </div>
      </div>

      <AppVersion version={import.meta.env.PACKAGE_VERSION} />
    </nav>
  ) : (
    <nav
      className={`navigation ${navigationOptions.isCollapsed && "collapsed"}`}
    >
      <div className="menu-toggler" onClick={toggleSidebar}>
        {navigationOptions.isCollapsed ? (
          <CaretDoubleRight size={16} />
        ) : (
          <CaretDoubleLeft size={16} />
        )}
      </div>
      <div className="header-user">
        <div className="c-dropdown">
          <button className="c-button c-dropdown-button header-userDropdownButton">
            <Avatar name={user?.name} className="header-userAvatar" />
          </button>
        </div>
      </div>

      <div className="navigation-content navigation-content-v2">
        <ul className={"navigation-list"}>
          {routes.map((route) => {
            return (
              route.showInNavigation && (
                <li key={route.path} className="navigation-item">
                  <NavLink
                    to={`/${intl.locale}${route.path}`}
                    className={`navigation-link navigation-link-v2 ${
                      path === route.path ? "is-navigation-link--active" : ""
                    }`}
                    activeClassName="is-navigation-link--active"
                  >
                    {route.icon}
                    {route.iconActive}
                    <span className="tooltip-msg">
                      {intl.messages?.navigation?.[route.label]}
                    </span>
                  </NavLink>
                </li>
              )
            );
          })}
        </ul>
      </div>
      <div className="navigation-settings">
        <NavLink
          to={`/${intl?.locale}/app/settings`}
          className="navigation-link u-margin-b-2"
          activeClassName="is-navigation-link--active"
        >
          <Gear className="icon" size={20} />
          <Gear className="icon-active" size={20} weight="fill" />
          <span className="tooltip-msg">
            {intl?.messages?.navigation?.settings}
          </span>
        </NavLink>
        <div onClick={logout} className="navigation-link">
          <LogoutIcon width="20" />
          <span className="tooltip-msg">{intl?.messages?.login?.signOut}</span>
        </div>

        <AppVersion version={import.meta.env.PACKAGE_VERSION} />
      </div>
    </nav>
  );
};

export default Navigation;
