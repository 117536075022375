import { CalendarBlank, CaretDown, Check, WarningCircle } from "phosphor-react";
import React, { useState } from "react";
import Calendar from "react-calendar";
import Select from "react-dropdown-select";
import { useIntl } from "react-intl";
import { Waypoint } from "react-waypoint";

import { DropdownAvatar } from "../_avatar";

import { ITEMS_BEFORE_PAGE } from "#/src/constants/global";
import useDetermineDropdownPos from "#/src/hooks/_useDetermineDropdownPos";

export const DropdownMenuV2 = ({
  register,
  errorMessage,
  label,
  keyId,
  options = [],
  onChange = () => {},
  onDropdownOpen = () => {},
  values = [],
  placeholder = "Example Help Text",
  avatar = false,
  uppercaseLabel = false,
  disabled = false,
  dropdownHeight = "500px",
  closeOnSelect = true,
  trimLabelAt = 50,
  ...rest
}) => {
  const customHandlerIcon = () => {
    return <CaretDown size={12} />;
  };

  const customDropdownRender = ({ props, state, methods }) => {
    const regexp = new RegExp(state.search, "i");
    const searchBy = props.searchBy?.replace(" ", "")?.split(",");

    const items = props.searchable
      ? props.options?.filter((item) => {
          if (searchBy?.length > 1) {
            let found = false;
            for (let i = 0; i < searchBy?.length; i++) {
              found = regexp.test(item[searchBy[i]]);
              if (found) {
                break;
              }
            }
            return found;
          } else {
            return regexp.test(item[props.searchBy] || item[props.labelField]);
          }
        })
      : props.options;

    return (
      <>
        {!items?.length && (
          <span className="no-value">No available option</span>
        )}

        {items?.map((option, index) => {
          const foundIndex = state.values.findIndex(
            (v) => v.value === option.value,
          );
          const found = foundIndex >= 0;

          return (
            <div
              key={option.value}
              onClick={() => methods.addItem(option)}
              className={`dropdown-item-v2-select-option${
                found ? " selected" : ""
              }`}
            >
              {avatar && (
                <DropdownAvatar
                  className="dropdown-item-avatar"
                  name={option.name || option.label}
                />
              )}

              {/* {trimText(option.label, trimLabelAt)} */}
              {option.label}

              {found && (
                <span className="dropdown-item-v2-selected-icon">
                  <Check size={16} />
                </span>
              )}
            </div>
          );
        })}
      </>
    );
  };

  return (
    <div
      className={`dropdown-menu-v2-wrapper ${rest.className}${
        errorMessage ? " has-error" : ""
      } ${disabled && "disabled-block"}`}
    >
      {label && (
        <label
          htmlFor={keyId}
          className={`input-v2-label ${
            uppercaseLabel ? "input-v2-label--uppercase" : ""
          } c-label`}
        >
          {label}
        </label>
      )}

      <Select
        id={keyId}
        dropdownHandleRenderer={customHandlerIcon}
        searchable={false}
        className={`dropdown-menu-v2${values.length ? " has-value" : ""}`}
        placeholder={placeholder}
        options={options}
        dropdownHeight={dropdownHeight}
        closeOnSelect={closeOnSelect}
        values={values}
        onChange={onChange}
        dropdownRenderer={customDropdownRender}
        disabled={disabled}
        onDropdownOpen={onDropdownOpen}
        {...rest}
      />

      {errorMessage && (
        <p className="input-v2-error-label">
          <WarningCircle className="input-v2-error-icon" size={16} />
          {errorMessage}
        </p>
      )}
    </div>
  );
};

export const AsyncDropdownMenu = ({
  register,
  errorMessage,
  label,
  keyId,
  options = [],
  onChange = () => {},
  onDropdownOpen = () => {},
  values = [],
  placeholder = "Example Help Text",
  avatar = false,
  uppercaseLabel = false,
  disabled = false,
  dropdownHeight = "200px",
  closeOnSelect = true,
  trimLabelAt = 50,
  dropdownPosition = "bottom",
  onScrollToBottom,
  onSearch,
  isLoading,
  ...rest
}) => {
  const customHandlerIcon = () => {
    return <CaretDown size={12} />;
  };

  const { messages } = useIntl();

  const customDropdownRender = ({ props, state, methods }) => {
    if (onSearch) {
      onSearch(state.search);
    }

    const items = props.options;

    return (
      <>
        {items?.map((option, index) => {
          const foundIndex = state.values.findIndex(
            (v) => v.value === option.value,
          );
          const found = foundIndex >= 0;
          const IconComp = option.iconName;

          return (
            <React.Fragment key={option.value}>
              <div
                key={option.value}
                onClick={() => methods.addItem(option)}
                className={`dropdown-item-v2-select-option${
                  found ? " selected" : ""
                }`}
              >
                {avatar && (
                  <DropdownAvatar
                    className="dropdown-item-avatar"
                    name={option.name || option.label}
                  />
                )}

                <div className="u-flex">
                  {option.iconName && (
                    <div
                      className={`u-margin-r-1 u-flex u-items-center u-justify-center u-rounded u-text-small--xs u-font-bold ${option.iconType}-bg-color`}
                    >
                      <IconComp
                        width={14}
                        height={14}
                        className={`${option.iconType}-icon-color`}
                        style={option.iconStyle || {}}
                      />
                    </div>
                  )}
                  <div>{option.label}</div>
                </div>

                {found && (
                  <span className="dropdown-item-v2-selected-icon">
                    <Check size={16} />
                  </span>
                )}
              </div>
              {index === items.length - ITEMS_BEFORE_PAGE ? (
                <Waypoint onEnter={onScrollToBottom} />
              ) : null}
            </React.Fragment>
          );
        })}

        {isLoading ? (
          <span className="no-value">{messages?.common?.loading}</span>
        ) : null}

        {!items?.length && !isLoading && (
          <span className="no-value">{messages?.common?.noOption}</span>
        )}
      </>
    );
  };

  return (
    <div
      className={`dropdown-menu-v2-wrapper${errorMessage ? " has-error" : ""}`}
    >
      {label && (
        <label
          htmlFor={keyId}
          className={`input-v2-label ${
            uppercaseLabel ? "input-v2-label--uppercase" : ""
          } c-label`}
        >
          {label}
        </label>
      )}

      <Select
        id={keyId}
        dropdownHandleRenderer={customHandlerIcon}
        searchable={false}
        className={`dropdown-menu-v2${values.length ? " has-value" : ""}`}
        placeholder={placeholder}
        options={options}
        dropdownHeight={dropdownHeight}
        closeOnSelect={closeOnSelect}
        values={values}
        onChange={onChange}
        dropdownRenderer={customDropdownRender}
        disabled={disabled}
        onDropdownOpen={onDropdownOpen}
        dropdownPosition={dropdownPosition}
        {...rest}
      />

      {errorMessage && (
        <p className="input-v2-error-label">
          <WarningCircle className="input-v2-error-icon" size={16} />
          {errorMessage}
        </p>
      )}
    </div>
  );
};

export const DateInput = ({
  uppercaseLabel,
  label,
  keyId,
  defaultValue,
  showFormat = false,
  onChange: onDateChange,
  errorMessage,
  value,
  required = false,
  format = "DD-MM-YYYY",
  dropPosition = "bottom",
  disabled = false,
  placeholder,
  maxDate,
  dropdownClassname = "",
  scrollContainerSelector,
  ...props
}) => {
  const ref = React.useRef(null);
  const [hasValue, setHasValue] = React.useState(false);
  const [showCalendar, setShowCalendar] = React.useState(false);
  const classes = [
    "input-v2",
    "input-v2-has-value",
    errorMessage ? "input-v2-error" : "",
  ].join(" ");

  const dropPos = useDetermineDropdownPos(
    ref,
    320,
    dropPosition,
    scrollContainerSelector,
  );

  const handleOpenDropdown = () => {
    if (disabled) return;
    setShowCalendar(true);
  };

  React.useEffect(() => {
    if (defaultValue || value) {
      setHasValue(true);
    } else {
      setHasValue(false);
    }
  }, [defaultValue, value]);

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowCalendar(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <div className="input-v2-wrapper">
      {label && (
        <label
          htmlFor={keyId}
          className={`input-v2-label c-label ${
            uppercaseLabel ? "c-label--uppercase" : ""
          }`}
        >
          {label}
        </label>
      )}

      <div
        ref={ref}
        className="date-input-container "
        onFocus={handleOpenDropdown}
      >
        <input
          readOnly
          id={keyId}
          className={classes}
          value={
            showFormat
              ? defaultValue || ""
              : moment(value || defaultValue).format(format)
          }
          placeholder={
            showFormat && !placeholder?.length ? "yyyy/mm/dd" : placeholder
          }
          {...props}
        />
        <div className="calendar-icon" onClick={handleOpenDropdown}>
          <CalendarBlank />
        </div>
        <div
          className={`date-input-calendar-container ${dropPos} ${dropdownClassname}`}
          style={{ display: showCalendar ? "inline-block" : "none" }}
        >
          <Calendar
            calendarType="ISO 8601"
            showNeighboringMonth={false}
            className="date-input-calendar"
            value={value ? new Date(value) : new Date()}
            maxDate={maxDate}
            onChange={(v) => {
              setShowCalendar(false);
              onDateChange(v);
            }}
          />
        </div>
      </div>

      {errorMessage && (
        <p className="input-v2-error-label">
          <WarningCircle className="input-v2-error-icon" size={16} />
          {errorMessage}
        </p>
      )}
    </div>
  );
};
